var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mobile-search-width margin"
  }, [_c('b-button-group', [_c('form', {
    staticClass: "search_form",
    attrs: {
      "id": "searchForm",
      "role": "form"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.searchDataAction.apply(null, arguments);
      }
    }
  }, [_c('button', {
    staticClass: "btn-search border-radius-l-32",
    attrs: {
      "type": "submit"
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "search",
      "variant": "black"
    }
  })], 1), _c('Vue-Simple-Suggest', {
    attrs: {
      "nullable-select": true,
      "debounce": 1000,
      "display-attribute": "name",
      "value-attribute": "_id",
      "placeholder": "Search for Product / Service",
      "list": _vm.searchSuggestions
    },
    on: {
      "select": _vm.searchDataAction
    },
    model: {
      value: _vm.searchString,
      callback: function callback($$v) {
        _vm.searchString = $$v;
      },
      expression: "searchString"
    }
  }), _c('button', {
    staticClass: "btnclose rounded-pill text-grey",
    attrs: {
      "type": "reset"
    }
  }, [_vm._v(" × ")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }